import React from 'react'
import BlogListItem from '../blog/list-item'
const { slice } = require('lodash')

const CustomerStoriesRelated = ({ heading = "Related", articles, relatedTags = null }) => {

    if (articles.length) {
        return (
            <div className="c-blog-listing c-blog-listing--featured">
                <div className="container">
                    <div className="row">
                        <div className="c-blog-listing__header">
                            <h2 className="c-blog-listing__header-title">{heading}</h2>
                            <a href={`/${articles[0].url.split('/')[1]}`} className="c-blog-listing__header-link c-btn c-btn--primary">Discover more</a>
                        </div>
                    </div>
                    <div className="row">
                        {slice(articles, 0, 3).map((article, i) => {
                            return (
                                <BlogListItem key={`${i}`} post={article} relatedTags={relatedTags} hideTags={true} />
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    return null
}

export default CustomerStoriesRelated